@font-face {
    font-family: VerelaRound;
    src: url("./fonts/VarelaRound.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: VerelaRound, 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
}

.fillContentHeight {
    min-height: var(--content-height);
}

.inlineLink {
    font-weight: bold;
}

.inlineLink:hover {
    text-decoration: underline;
}

* {
    -webkit-tap-highlight-color: #0000;
    box-sizing: border-box;
    outline: none;
}
